import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { ButtonSecondary } from '../../../components/atoms/Button/ButtonSecondary'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  return (
    <LayoutIR
      heading="経営方針"
      headingEnglish={ENGLISH_TITLE.ManagementPolicy}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '経営方針', url: '' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pc:pb-12 bg-white">
        <div className="pc:flex flex-wrap">
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pr-3">
            <ButtonSecondary
              label="代表メッセージ"
              url="/ir/policy/management"
            />
          </div>
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pl-3">
            <ButtonSecondary
              label="企業理念・行動指針"
              url="/ir/policy/philosophy"
            />
          </div>
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pr-3">
            <ButtonSecondary
              label="CRGグループの強み"
              url="/ir/policy/strength"
            />
          </div>
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pl-3">
            <ButtonSecondary
              label="ディスクロージャーポリシー"
              url="/ir/policy/disclosure"
            />
          </div>
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pr-3">
            <ButtonSecondary
              label="コーポレートガバナンス"
              url="/ir/policy/governance"
            />
          </div>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEOIR title="経営方針" url="/ir/policy" />
